/* customCarousel.css */
.carousel .slider-wrapper {
    overflow: hidden;
  }
  
  .carousel .slider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel .control-dots {
    display: flex !important;
    justify-content: center;
    margin-top:50px;
    padding: 0;
    list-style-type: none;
  }
  
  .carousel .control-dots li {
    display: inline-block;
  }
  