* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  /* list-style-type: none; */
}

body {
  width: 100%;
  overflow-x: hidden;
}

:root {
  --ligth-orange-color: #F7882F;
  --dark-orange-color: #C15810;
  --ligth-gray-color: #999999;
  --dark-gray-color: #333333;
  --cream-color: #DCC7AA;
  --yelow-color: #F7C331;
}
html {
  scroll-behavior: smooth;
}
